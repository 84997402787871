.Div--android, .Div--ios {
    padding: 0 16px 0 16px;
}

.FormNW-title {
    margin-top: 10px;
    font-size: 1.4em;
    color: #222E35;
}

.FormNW-text {
    font-size: 1em;
    color: #222E35;
}

.FormNW-bt, .FormNW-bt-none {
    width: 100%;
    height: 55px;
    border-radius: 12px;
    border: none;
    background: #E05047;
    color: #FFF;
    outline: none;
    font-size: 1.2em;
    font-family: Gotham Pro;
    text-align: center;
    margin-bottom: 10px;
}

.FormNW-bt-none {
    opacity: .8;
}

.FormNW-foo {
    font-size: .9em;
    color: #222E35;
}

#rock {
    background-image: url('../../img/rock.png');
    background-size: cover;
    width: 200px;
    height: 200px;
    margin-left: calc(50% - 90px);
    margin-top: 60px;
}
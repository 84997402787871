.overlow {
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, .3);
    -webkit-transition: visibility 0.5s linear, opacity 0.5s linear;
    transition: visibility 0.5s linear, opacity 0.5s linear;
}

.menu {
    position: fixed;
    z-index: 2;
    height: calc(100vh - 44px - 20px);
    height: calc(100vh - 44px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(100vh - 44px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    width: 234px;
    background: #87C2E7;
    padding-top: calc(44px + constant(safe-area-inset-top));
    padding-top: calc(44px + env(safe-area-inset-top));
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    left: -234px;
    -webkit-transition: left 0.4s;
    transition: left 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}

.menu-dog {
    background-image: url('./img/prodolzhai.png');   
    background-size: cover;
    width: 200px;
    height: 200px;
    margin-left: 20px;
    margin-bottom: 50px;
}

.menu-item {
    height: 56px;
    border-bottom: 1px solid #9DD2F4;
    padding-left: 20px;
    color: #FFF;
    font-family: Gotham Pro;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

@media only screen and (max-height: 580px) {
    .menu-dog {
        display: none;
    }
}
.f-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 60px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
}

.f-text {
    color: #FFF;
    width: 100%;
    text-align: center;
}

.f-text p {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 500;
}

.f-text span {
    font-size: 15px;
}

.f-bt {
    margin-top: 20px;
    width: 80%;
    margin-left: 10%;
    height: 55px;
    background: #E05047;
    border-radius: 12px;
    color: #FFF;
    font-weight: 500;
    border: none;
    outline: none;
    font-size: 14px;
}

#pes {
    background-image: url(/static/media/privet_logo.bdff1afa.png);
    background-size: cover;
    width: 200px;
    height: 200px;
    margin-left: calc(50% - 100px);
    margin-bottom: 60px;
}
.header-title {
    display: flex;
    align-items: center;
    font-size: var(--font-size-title-header);
}

.header-title img {
    margin-right: 10px;
}

@media only screen and (max-width: 380px) {
    .header-title img {
        display: none;
    }
}

.main-title-m {
    /* padding-top: calc(100% - 204px); */
    /* height: -webkit-fill-available; */
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
}

.main-title-bl .span {
    text-align: center;
    margin-bottom: 40px;
}

.main-bt-block {
    margin-top: 20px;
}

.main-title a {
    text-decoration: none;
    color: #000;
    text-align: center;
    font-weight: 500;
}

.main-title a div {
    /* margin-bottom: 20px; */
}

#donor {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #87C2E7;
}

#donor-img, #luna-img {
    min-width: 72px;
    min-height: 72px;
    max-width: 72px;
    max-height: 72px;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
}

#donor-img img {
    height: 40px;
}

#luna-img img {
    width: 50px;
}

#donor-bt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 10px;
}

#donor-bt a {
    background: #5181B8;
    color: #FFF;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 15px;
    font-size: .65em;
}

/* /////////////////////////////////////////////// */

#main-part div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

#main-part p {
    font-weight: 500;
    font-size: 17px;
}

#pgrants {
    width: 40%;
    margin: 10px 5% 10px 5%;
    cursor: pointer;
}

#pgrants img {
    width: 90%;
    height: 90%;
    /* margin-bottom: 10px; */
}

#pgrants div {
    width: 100%;
}

#pgrants div span {
    width: 100%;
}

#pgrants div div {
    background: #FFF;
    width: 100%;
    height: 130px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

#obrazfund {
    width: 40%;
    margin: 10px 5% 10px 5%;
}

#obrazfund img {
    width: 100%;
    height: 90%;
    border-radius: 6px;
    /* margin-bottom: 10px; */
}

#obrazfund div {
    width: 100%;
}

#obrazfund div span {
    width: 100%;
}

#obrazfund div div {
    margin-bottom: 8px;
    width: 100%;
}
.cross-content {
    height: calc(100vh - 75px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.corss-b-y {
    background: #FFF;
    height: 36.15px;
    width: 36.15px;
    min-width: 36.15px;
    min-height: 36.15px;
    text-align: center;
    line-height: 36.15px;
    border-radius: 6px;
}

.corss-b-y-n {
    margin: 0;
    width: 0;
    border: 0;
}

.cross-content-btCon, .cross-content-btCon-none {
    height: 55px;
    width: 100%;
    margin-top: 7px;
    border-radius: 12px;
    border: none;
    outline: none;
    color: #FFF;
    font-size: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cross-content-btCon:active {
    opacity: .3;
}

.cross-content-btCon a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #FFF;
}

.cross-content-btCon-none {
    opacity: .8;
}

.corss-b-bt-v {
    background: #E05047;
    color: #FFF;
    height: 36.15px;
    width: 36.15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 6px;
}

.corss-b-bt-h {
    background: #E05047;
    height: 36.15px;
    width: 36.15px;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 6px;
}

.corss-b-bt-v button, .corss-b-bt-h button {
    background: #E05047;
    color: #FFF;
    border-radius: 6px;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 36px;
    max-height: 36.15px;
}

.corss-b-bt-h button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 36px;
    max-height: 36.15px;
}

.corss-b-bt-v button div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -20px;
    border-radius: 5px;
    height: 34.15px;
    width: 34.15px;
    font-size: 1.2em;
}

.corss-b-bt-h button div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* left: 5px; */
    top: -20px;
    border-radius: 5px;
    height: 34.15px;
    width: 34.15px;
    font-size: 1.2em;
}

.corss-b-bt-v button input {
    min-height: 34.15px;
    min-width: 34.15px;
    height: 34.15px;
    width: 34.15px;
    z-index: 12;
    position: relative;
    bottom: -17.075px;
    opacity: 0;
}

 input:focus ~ div {
    /* background: #F0f; */
    /* z-index: 1000000; */
    font-weight: 900;
    font-size: 1.6em;
}

.corss-b-bt-h button input {
    min-height: 34.15px;
    min-width: 34.15px;
    height: 34.15px;
    width: 34.15px;
    position: relative;
    bottom: -11.075px;
    /* right: 7.075px; */
    z-index: 12;
    opacity: 0;
}

.arrow-down, .arrow-right {
    height: 70%;
    width: 70%;
    min-height: 70%;
    min-width: 70%;
    display: none;
}

.cross-content-btCon-block {
    display: flex;
    margin-bottom: 20px;
}

.cross-content-btCon:nth-child(1), .cross-content-btCon-none:nth-child(1) {
    margin-right: 2.5px;
}

.cross-content-btCon:nth-child(2), .cross-content-btCon-none:nth-child(2) {
    margin-left: 2.5px;
}

.cross-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

#table-block {
    flex: 1 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}
.Panel__in {
    /* padding-top: 28px!important; */
}

.Group--android {
    /* box-shadow: none; */
}

.vict-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: -webkit-fill-available; */
}

.victo-bg {
    position: fixed;
    bottom: 0;
    height: auto;
    /* width: 100vw; */
    width: 100%;
    max-height: 150px;
}

.vict-start {
    /* flex: 1; */
    width: 100%;
    height: calc(100vh - 60px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
    display: flex;
    justify-content: center;
    align-items: center;
}

.vict-v-block {
    padding: 44px 5% 2px 5%;
    font-family: Gotham Pro;
    color: #222E35;
}

.vict-v-title {
    margin-bottom: 10px;
    font-size: 1em;
}

.vict-v-q {
    font-size: 1.5em;
    font-size: var(--font-size-title-vict);
}

.vict-bt-block {
    padding: 20px 5% 2px 5%;
    display: flex;
    flex-direction: column;
}

.vict-bt-bt-none {
    visibility: hidden;
}

.vict-bt-bt {
    margin-bottom: 9px;
    height: 4em;
    border-radius: 1.5em;
    border: none;
    outline: none;
    background: #4779BC;
    color: #FFF;
    font-family: Gotham Pro;
    font-size: 15px;
    line-height: 14px;
    text-align: center;
}

.modalWin {
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    min-height: 50px;
    max-height: calc(100vh - 160px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
    width: 80%;
    border-radius: 12px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.modalWin-title {
    color: #222E35;
    font-size: 1.2em;
    margin-bottom: 15px;
    font-weight: 500;
    text-align: center;
}

.modalWin-title span {
    font-weight: 700;
}

.modalWin-title p {
    color: #E05047;
    font-weight: 700;
}

.modalWin-content {
    width: calc(100% - 20px);
    margin-bottom: 15px;
    text-align: center;
}

.modalWin-bt, .modalWin-bt-dis {
    font-weight: 500;
    height: 40px;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
    border: none;
    font-size: 1em;
    color: #FFF;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalWin-bt-dis {
    opacity: .3;
}

.modalWin span {
    line-height: 20px;
    /* margin-left: 15px;
    margin-right: 15px; */
    text-align: center;
}

.modalWin-bt-block {
    width: 70%;
}

.modalWin button {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 100%;
    height: 45px;
    /* margin-left: 15%; */
    border-radius: 12px;
    background: #E05047;
    color: #FFF;
    font-size: 1em;
    outline: none;
    border: none;
}

@media only screen and (min-width: 600px) {}

@media only screen and (max-height: 600px) {
    .victo-bg {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .victo-bg {
        display: none;
    }
}
.Div--android, .Div--ios {
    padding: 0 16px 0 16px;
}

.FormNW-title {
    margin-top: 10px;
    font-size: 1.4em;
    color: #222E35;
}

.FormNW-text {
    font-size: 1em;
    color: #222E35;
}

.FormNW-bt, .FormNW-bt-none {
    width: 100%;
    height: 55px;
    border-radius: 12px;
    border: none;
    background: #E05047;
    color: #FFF;
    outline: none;
    font-size: 1.2em;
    font-family: Gotham Pro;
    text-align: center;
    margin-bottom: 10px;
}

.FormNW-bt-none {
    opacity: .8;
}

.FormNW-foo {
    font-size: .9em;
    color: #222E35;
}

#rock {
    background-image: url(/static/media/rock.4ecbe735.png);
    background-size: cover;
    width: 200px;
    height: 200px;
    margin-left: calc(50% - 90px);
    margin-top: 60px;
}
.rating-title {
    margin-top: 20px;
    margin-bottom: 14px;
    height: 30px;
    font-size: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rating-title-bt {
    height: 30px;
    background: #4879B9;
    outline: none;
    border: none;
    border-radius: 15px;
    color: #FFF;
}

.rating-title-a {
    height: 30px;
    background: #4879B9;
    outline: none;
    border: none;
    text-decoration: none;
    border-radius: 15px;
    color: #FFF;
    padding-left: 2px;
    padding-right: 5px;
}

.rating-title-a div {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rating-title-a span {
    font-size: .5em;
}

.rating-title-a img {
    height: 26px;
    width: 26px;
    border-radius: 13px;
    margin-right: 3px;
}

.navi-block {
    margin-bottom: 15px;
}

.navi-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
}

.navi-item {
    flex: 1 1;
    font-size: .9em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navi-stick {
    background: #D1EEFD;
    height: 4px;
    border-radius: 2px;
    margin-top: 5px;
}

.navi-stick-item {
    background: #4779BC;
    width: 33.33%;
    height: 4px;
    border-radius: 2px;
    position: relative;
    left: 0;
    transition: left 0.5s;
}

.meRatingNone {
    max-height: 60px;
    height: 60px;
    text-align: center;
}

.meRatingNone p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 1em;
}

.meRatingNone span {
    margin: 0;
    margin-bottom: 5px;
    font-size: .7em;
}

.rating-list {
    /* background: #F00; */
    height: calc(100vh - 155px - 90px);
    height: calc(100vh - 155px - 70px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(100vh - 155px - 70px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.rating-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    padding: 10px 17px 10px 10px;
    font-size: 1em;
    margin-bottom: 10px;
    overflow: hidden;
}

.rating-list-item:nth-child(-n+11) {
    border: 3px solid #4779BC;
}

.rating-list-item-l {
    display: flex;
    align-items: center;
}

.rating-list-item-l-l img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 13px;
}

.rating-list-item-l-r {}

.rating-list-item-l-r-t {
    margin-bottom: 5px;
}

.rating-list-item-l-r-b {
    margin-top: 5px;
    opacity: 0.5;
    overflow: hidden;
}

.overlowM {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalR {
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    min-height: 50px;
    width: 80%;
    border-radius: 12px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalR-title {
    font-size: 1.2em;
    margin-bottom: 25px;
    font-weight: 500;
}

.modalR-content {
    width: calc(100% - 20px);
    margin-bottom: 15px;
}

.tableMBD {
    width: 100%;
    table-layout: fixed;
    text-align: center;
}

.tableMBD tr td {
    width: 100%;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* background-image: url(...); */
    height: 30px;
    width: 100%;
    padding-left: 3em;
    background: #FFF;
    border-radius: 12px;
    border: 1px solid #9DD2F4;
    outline: none
}

select::-ms-expand {
    display: none;
}

select:focus {
    border: 1px solid #4779BC;
}

.modalR-bt-block {
    width: calc(100% - 20px);
}

.modalR-bt {
    height: 40px;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
    border: none;
    font-size: 1em;
    color: #FFF;
    outline: none;
}

#gorod {
    height: 30px;
    width: calc(100% - 30px);
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
    border: 1px solid #9DD2F4;
    margin-bottom: 10px;
}

.pod {}

#gorod:focus {
    border: 1px solid #4779BC;
}

#podskazka, #podskazka1, #podskazka2 {
    /* visibility: hidden; */
    background: #FFF;
    border: 1px solid #4779BC;
    min-height: 30px;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
    font-size: .8em;
    display: none;
    align-items: center;
}

.blood-block {
    display: flex;
    flex-wrap: wrap;
}

.blood-item {
    color: #FFF;
    background: #9DD2F4;
    height: 25px;
    /* width: 100px; */
    font-size: .8em;
    margin: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: none;
    outline: none;
}

.ListNone {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ListNone-conent p {
    width: 100%;
    text-align: center;
}

#bdate {
    height: 30px;
    width: calc(100% - 2px);
    outline: none;
    border-radius: 15px;
    border: 1px solid #9DD2F4;
    margin-bottom: 10px;    
    text-align: center;
    font-size: 16px;
    outline: none;
    padding-left: 0;
    padding-right: 0;
}

#bdate:focus{
    border: 1px solid #4779BC;
}
@font-face {
    font-family: 'Gotham Pro';
    src: url(/static/media/GothaProMed.dd3d9ca5.otf);
    font-weight: 500;
    font-style: medium;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url(/static/media/GothaProReg.aafeb237.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url(/static/media/GothaProBol.3f9c5a2b.otf);
    font-weight: bold;
    font-style: normal;
}

* {
    -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    /* background:  #E05047; */
}

input, textarea {
    -webkit-user-select: text;
    -moz-user-select: text;
     -ms-user-select: text;
         user-select: text;
    /* background:  #E05047; */
}

#exitToo {
    z-index: 5000;
    position: absolute;
    height: calc(100vh - 50px);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 50px;
}

#exitToo span {
    margin-top: 40px;
    background: rgba(0, 0, 0, 0.3);
    color: #FFF;
    padding: 5px 10px 5px 10px;
    border-radius: 12px;
}

.HeaderButton--ios {
    padding: 0 12px;
}

body {
    --header_background: #87C2E7;
    --header_alternate_background: #87C2E7;
    --background_page: #BEE4F7;
    --background_content: #BEE4F7;
    --text_secondary: #000;
    --button_secondary_foreground: #FFF;
    --button_secondary_background: #4779BC;
    --font-android: Gotham Pro;
    --font-ios: Gotham Pro;
    --font-size-title-vict: 120%;
    --font-size-title-header: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
}

.PanelHeader-bg::after {
    height: 0!important;
}

.cssload {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.cssload svg {
    margin-top: 100px;
}

.cssload-main {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-100%, -240%);
    -o-transform: translate(-100%, -240%);
    -ms-transform: translate(-100%, -240%);
    -webkit-transform: translate(-100%, -240%);
    -moz-transform: translate(-100%, -240%);
}

.cssload-main * {
    font-size: 60px;
}

.cssload-heart {
    animation: cssload-heart 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-heart 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-heart 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-heart 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-heart 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    top: 50%;
    content: '';
    left: 50%;
    position: absolute;
}

.cssload-heartL {
    width: 1em;
    height: 1em;
    border: 1px solid rgb(224, 81, 71);
    background-color: rgb(224, 81, 71);
    content: '';
    position: absolute;
    display: block;
    border-radius: 100%;
    animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-heartL 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    transform: translate(-28px, -27px);
    -o-transform: translate(-28px, -27px);
    -ms-transform: translate(-28px, -27px);
    -webkit-transform: translate(-28px, -27px);
    -moz-transform: translate(-28px, -27px);
}

.cssload-heartR {
    width: 1em;
    height: 1em;
    border: 1px solid rgb(224, 81, 71);
    background-color: rgb(224, 81, 71);
    content: '';
    position: absolute;
    display: block;
    border-radius: 100%;
    transform: translate(28px, -27px);
    -o-transform: translate(28px, -27px);
    -ms-transform: translate(28px, -27px);
    -webkit-transform: translate(28px, -27px);
    -moz-transform: translate(28px, -27px);
    animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-heartR 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
}

.cssload-square {
    width: 1em;
    height: 1em;
    border: 1px solid rgb(224, 81, 71);
    background-color: rgb(224, 81, 71);
    position: relative;
    display: block;
    content: '';
    transform: scale(1) rotate(-45deg);
    -o-transform: scale(1) rotate(-45deg);
    -ms-transform: scale(1) rotate(-45deg);
    -webkit-transform: scale(1) rotate(-45deg);
    -moz-transform: scale(1) rotate(-45deg);
    animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-square 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
}

.cssload-shadow {
    top: 97px;
    left: 50%;
    content: '';
    position: relative;
    display: block;
    bottom: -.5em;
    width: 1em;
    height: .24em;
    background-color: rgb(215, 215, 215);
    border: 1px solid rgb(215, 215, 215);
    border-radius: 50%;
    animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -o-animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -ms-animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -webkit-animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    -moz-animation: cssload-shadow 2.88s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
}

@media only screen and (max-height: 700px) {
    body {
        --font-size-title-vict: 100%;
        --font-size-title-header: 80%;
    }
}

@keyframes cssload-square {
    50% {
        border-radius: 100%;
        transform: scale(0.5) rotate(-45deg);
    }
    100% {
        transform: scale(1) rotate(-45deg);
    }
}

@-webkit-keyframes cssload-square {
    50% {
        border-radius: 100%;
        -webkit-transform: scale(0.5) rotate(-45deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(-45deg);
    }
}

@keyframes cssload-heart {
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(720deg);
    }
}

@-webkit-keyframes cssload-heart {
    50% {
        -webkit-transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(720deg);
    }
}

@keyframes cssload-heartL {
    60% {
        transform: scale(0.4);
    }
}

@-webkit-keyframes cssload-heartL {
    60% {
        -webkit-transform: scale(0.4);
    }
}

@keyframes cssload-heartR {
    40% {
        transform: scale(0.4);
    }
}

@-webkit-keyframes cssload-heartR {
    40% {
        -webkit-transform: scale(0.4);
    }
}

@keyframes cssload-shadow {
    50% {
        transform: scale(0.5);
        border-color: rgb(228, 228, 228);
    }
}

@-webkit-keyframes cssload-shadow {
    50% {
        -webkit-transform: scale(0.5);
        border-color: rgb(228, 228, 228);
    }
}
.overlow {
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, .3);
    transition: visibility 0.5s linear, opacity 0.5s linear;
}

.menu {
    position: fixed;
    z-index: 2;
    height: calc(100vh - 44px - 20px);
    height: calc(100vh - 44px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(100vh - 44px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    width: 234px;
    background: #87C2E7;
    padding-top: calc(44px + constant(safe-area-inset-top));
    padding-top: calc(44px + env(safe-area-inset-top));
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    left: -234px;
    transition: left 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}

.menu-dog {
    background-image: url(/static/media/prodolzhai.ca483d3e.png);   
    background-size: cover;
    width: 200px;
    height: 200px;
    margin-left: 20px;
    margin-bottom: 50px;
}

.menu-item {
    height: 56px;
    border-bottom: 1px solid #9DD2F4;
    padding-left: 20px;
    color: #FFF;
    font-family: Gotham Pro;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

@media only screen and (max-height: 580px) {
    .menu-dog {
        display: none;
    }
}

.rating-title {
    margin-top: 20px;
    margin-bottom: 14px;
    height: 30px;
    font-size: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rating-title-bt {
    height: 30px;
    background: #4879B9;
    outline: none;
    border: none;
    border-radius: 15px;
    color: #FFF;
}

.rating-title-a {
    height: 30px;
    background: #4879B9;
    outline: none;
    border: none;
    text-decoration: none;
    border-radius: 15px;
    color: #FFF;
    padding-left: 2px;
    padding-right: 5px;
}

.rating-title-a div {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rating-title-a span {
    font-size: .5em;
}

.rating-title-a img {
    height: 26px;
    width: 26px;
    border-radius: 13px;
    margin-right: 3px;
}

.navi-block {
    margin-bottom: 15px;
}

.navi-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
}

.navi-item {
    flex: 1;
    font-size: .9em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navi-stick {
    background: #D1EEFD;
    height: 4px;
    border-radius: 2px;
    margin-top: 5px;
}

.navi-stick-item {
    background: #4779BC;
    width: 33.33%;
    height: 4px;
    border-radius: 2px;
    position: relative;
    left: 0;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
}

.meRatingNone {
    max-height: 60px;
    height: 60px;
    text-align: center;
}

.meRatingNone p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 1em;
}

.meRatingNone span {
    margin: 0;
    margin-bottom: 5px;
    font-size: .7em;
}

.rating-list {
    /* background: #F00; */
    height: calc(100vh - 155px - 90px);
    height: calc(100vh - 155px - 70px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(100vh - 155px - 70px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.rating-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    padding: 10px 17px 10px 10px;
    font-size: 1em;
    margin-bottom: 10px;
    overflow: hidden;
}

.rating-list-item:nth-child(-n+11) {
    border: 3px solid #4779BC;
}

.rating-list-item-l {
    display: flex;
    align-items: center;
}

.rating-list-item-l-l img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 13px;
}

.rating-list-item-l-r {}

.rating-list-item-l-r-t {
    margin-bottom: 5px;
}

.rating-list-item-l-r-b {
    margin-top: 5px;
    opacity: 0.5;
    overflow: hidden;
}

.overlowM {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalR {
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    min-height: 50px;
    width: 80%;
    border-radius: 12px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalR-title {
    font-size: 1.2em;
    margin-bottom: 25px;
    font-weight: 500;
}

.modalR-content {
    width: calc(100% - 20px);
    margin-bottom: 15px;
}

.tableMBD {
    width: 100%;
    table-layout: fixed;
    text-align: center;
}

.tableMBD tr td {
    width: 100%;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* background-image: url(...); */
    height: 30px;
    width: 100%;
    padding-left: 3em;
    background: #FFF;
    border-radius: 12px;
    border: 1px solid #9DD2F4;
    outline: none
}

select::-ms-expand {
    display: none;
}

select:focus {
    border: 1px solid #4779BC;
}

.modalR-bt-block {
    width: calc(100% - 20px);
}

.modalR-bt {
    height: 40px;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
    border: none;
    font-size: 1em;
    color: #FFF;
    outline: none;
}

#gorod {
    height: 30px;
    width: calc(100% - 30px);
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
    border: 1px solid #9DD2F4;
    margin-bottom: 10px;
}

.pod {}

#gorod:focus {
    border: 1px solid #4779BC;
}

#podskazka, #podskazka1, #podskazka2 {
    /* visibility: hidden; */
    background: #FFF;
    border: 1px solid #4779BC;
    min-height: 30px;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
    font-size: .8em;
    display: none;
    align-items: center;
}

.blood-block {
    display: flex;
    flex-wrap: wrap;
}

.blood-item {
    color: #FFF;
    background: #9DD2F4;
    height: 25px;
    /* width: 100px; */
    font-size: .8em;
    margin: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    border: none;
    outline: none;
}

.ListNone {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ListNone-conent p {
    width: 100%;
    text-align: center;
}

#bdate {
    height: 30px;
    width: calc(100% - 2px);
    outline: none;
    border-radius: 15px;
    border: 1px solid #9DD2F4;
    margin-bottom: 10px;    
    text-align: center;
    font-size: 16px;
    outline: none;
    padding-left: 0;
    padding-right: 0;
}

#bdate:focus{
    border: 1px solid #4779BC;
}
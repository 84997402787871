.Panel__in {
    /* padding-top: 28px!important; */
}

.Group--android {
    /* box-shadow: none; */
}

.vict-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: -webkit-fill-available; */
}

.victo-bg {
    position: fixed;
    bottom: 0;
    height: auto;
    /* width: 100vw; */
    width: 100%;
    max-height: 150px;
}

.vict-start {
    /* flex: 1; */
    width: 100%;
    height: calc(100vh - 60px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
    display: flex;
    justify-content: center;
    align-items: center;
}

.vict-v-block {
    padding: 44px 5% 2px 5%;
    font-family: Gotham Pro;
    color: #222E35;
}

.vict-v-title {
    margin-bottom: 10px;
    font-size: 1em;
}

.vict-v-q {
    font-size: 1.5em;
    font-size: var(--font-size-title-vict);
}

.vict-bt-block {
    padding: 20px 5% 2px 5%;
    display: flex;
    flex-direction: column;
}

.vict-bt-bt-none {
    visibility: hidden;
}

.vict-bt-bt {
    margin-bottom: 9px;
    height: 4em;
    border-radius: 1.5em;
    border: none;
    outline: none;
    background: #4779BC;
    color: #FFF;
    font-family: Gotham Pro;
    font-size: 15px;
    line-height: 14px;
    text-align: center;
}

.modalWin {
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    min-height: 50px;
    max-height: calc(100vh - 160px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
    width: 80%;
    border-radius: 12px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.modalWin-title {
    color: #222E35;
    font-size: 1.2em;
    margin-bottom: 15px;
    font-weight: 500;
    text-align: center;
}

.modalWin-title span {
    font-weight: 700;
}

.modalWin-title p {
    color: #E05047;
    font-weight: 700;
}

.modalWin-content {
    width: calc(100% - 20px);
    margin-bottom: 15px;
    text-align: center;
}

.modalWin-bt, .modalWin-bt-dis {
    font-weight: 500;
    height: 40px;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
    border: none;
    font-size: 1em;
    color: #FFF;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalWin-bt-dis {
    opacity: .3;
}

.modalWin span {
    line-height: 20px;
    /* margin-left: 15px;
    margin-right: 15px; */
    text-align: center;
}

.modalWin-bt-block {
    width: 70%;
}

.modalWin button {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 100%;
    height: 45px;
    /* margin-left: 15%; */
    border-radius: 12px;
    background: #E05047;
    color: #FFF;
    font-size: 1em;
    outline: none;
    border: none;
}

@media only screen and (min-width: 600px) {}

@media only screen and (max-height: 600px) {
    .victo-bg {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .victo-bg {
        display: none;
    }
}
.header-title {
    display: flex;
    align-items: center;
    font-size: var(--font-size-title-header);
}

.header-title img {
    margin-right: 10px;
}

@media only screen and (max-width: 380px) {
    .header-title img {
        display: none;
    }
}

.main-title-m {
    /* padding-top: calc(100% - 204px); */
    /* height: -webkit-fill-available; */
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
}

.main-title-bl .span {
    text-align: center;
    margin-bottom: 40px;
}

.main-bt-block {
    margin-top: 20px;
}

.main-title a {
    text-decoration: none;
    color: #000;
    text-align: center;
    font-weight: 500;
}

.main-title a div {
    /* margin-bottom: 20px; */
}

#donor {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #87C2E7;
}

#donor-img, #luna-img {
    min-width: 72px;
    min-height: 72px;
    max-width: 72px;
    max-height: 72px;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
}

#donor-img img {
    height: 40px;
}

#luna-img img {
    width: 50px;
}

#donor-bt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 10px;
}

#donor-bt a {
    background: #5181B8;
    color: #FFF;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 15px;
    font-size: .65em;
}

/* /////////////////////////////////////////////// */

#main-part div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

#main-part p {
    font-weight: 500;
    font-size: 17px;
}

#pgrants {
    width: 40%;
    margin: 10px 5% 10px 5%;
    cursor: pointer;
}

#pgrants img {
    width: 90%;
    height: 90%;
    /* margin-bottom: 10px; */
}

#pgrants div {
    width: 100%;
}

#pgrants div span {
    width: 100%;
}

#pgrants div div {
    background: #FFF;
    width: 100%;
    height: 130px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

#obrazfund {
    width: 40%;
    margin: 10px 5% 10px 5%;
}

#obrazfund img {
    width: 100%;
    height: 90%;
    border-radius: 6px;
    /* margin-bottom: 10px; */
}

#obrazfund div {
    width: 100%;
}

#obrazfund div span {
    width: 100%;
}

#obrazfund div div {
    margin-bottom: 8px;
    width: 100%;
}
.cross-content {
    height: calc(100vh - 75px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.corss-b-y {
    background: #FFF;
    height: 36.15px;
    width: 36.15px;
    min-width: 36.15px;
    min-height: 36.15px;
    text-align: center;
    line-height: 36.15px;
    border-radius: 6px;
}

.corss-b-y-n {
    margin: 0;
    width: 0;
    border: 0;
}

.cross-content-btCon, .cross-content-btCon-none {
    height: 55px;
    width: 100%;
    margin-top: 7px;
    border-radius: 12px;
    border: none;
    outline: none;
    color: #FFF;
    font-size: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cross-content-btCon:active {
    opacity: .3;
}

.cross-content-btCon a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #FFF;
}

.cross-content-btCon-none {
    opacity: .8;
}

.corss-b-bt-v {
    background: #E05047;
    color: #FFF;
    height: 36.15px;
    width: 36.15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 6px;
}

.corss-b-bt-h {
    background: #E05047;
    height: 36.15px;
    width: 36.15px;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 6px;
}

.corss-b-bt-v button, .corss-b-bt-h button {
    background: #E05047;
    color: #FFF;
    border-radius: 6px;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 36px;
    max-height: 36.15px;
}

.corss-b-bt-h button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 36px;
    max-height: 36.15px;
}

.corss-b-bt-v button div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -20px;
    border-radius: 5px;
    height: 34.15px;
    width: 34.15px;
    font-size: 1.2em;
}

.corss-b-bt-h button div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* left: 5px; */
    top: -20px;
    border-radius: 5px;
    height: 34.15px;
    width: 34.15px;
    font-size: 1.2em;
}

.corss-b-bt-v button input {
    min-height: 34.15px;
    min-width: 34.15px;
    height: 34.15px;
    width: 34.15px;
    z-index: 12;
    position: relative;
    bottom: -17.075px;
    opacity: 0;
}

 input:focus ~ div {
    /* background: #F0f; */
    /* z-index: 1000000; */
    font-weight: 900;
    font-size: 1.6em;
}

.corss-b-bt-h button input {
    min-height: 34.15px;
    min-width: 34.15px;
    height: 34.15px;
    width: 34.15px;
    position: relative;
    bottom: -11.075px;
    /* right: 7.075px; */
    z-index: 12;
    opacity: 0;
}

.arrow-down, .arrow-right {
    height: 70%;
    width: 70%;
    min-height: 70%;
    min-width: 70%;
    display: none;
}

.cross-content-btCon-block {
    display: flex;
    margin-bottom: 20px;
}

.cross-content-btCon:nth-child(1), .cross-content-btCon-none:nth-child(1) {
    margin-right: 2.5px;
}

.cross-content-btCon:nth-child(2), .cross-content-btCon-none:nth-child(2) {
    margin-left: 2.5px;
}

.cross-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

#table-block {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}
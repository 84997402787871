.f-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 60px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
}

.f-text {
    color: #FFF;
    width: 100%;
    text-align: center;
}

.f-text p {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 500;
}

.f-text span {
    font-size: 15px;
}

.f-bt {
    margin-top: 20px;
    width: 80%;
    margin-left: 10%;
    height: 55px;
    background: #E05047;
    border-radius: 12px;
    color: #FFF;
    font-weight: 500;
    border: none;
    outline: none;
    font-size: 14px;
}

#pes {
    background-image: url('../../img/privet_logo.png');
    background-size: cover;
    width: 200px;
    height: 200px;
    margin-left: calc(50% - 100px);
    margin-bottom: 60px;
}